import { User } from '../interfaces';

const populateAuthPayloadFromDocumentCookie = (): User | false => {
  const match = document?.cookie?.match(new RegExp('(^| )jwt_p=([^;]+)'));

  try {
    if (match && match[2]) {
      const decoded = decodeURIComponent(
        atob(match[2])
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      );

      const jwtPayload = JSON.parse(decoded);

      if (jwtPayload.exp && jwtPayload.exp > new Date().getTime() / 1000) {
        return jwtPayload.user as User;
      }
    }
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return false;
};

export default populateAuthPayloadFromDocumentCookie;
