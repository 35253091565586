import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { AuthContext, AuthHandler, User } from '../interfaces';
import authActionsHandler from '../utils/action-handlers/authActionsHandler';
import populateAuthPayloadFromDocumentCookie from '../utils/populateAuthPayloadFromDocumentCookie';

const authContext = createContext<AuthContext>(null);

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContext {
  const [user, setUser] = useState<User | false>();

  useEffect(() => setUser(populateAuthPayloadFromDocumentCookie()), []);

  const renewStateFromCookies = (returnUrl?: string) => {
    const loggedInUser = populateAuthPayloadFromDocumentCookie();

    if (loggedInUser) {
      setUser(loggedInUser);
      if (typeof window !== 'undefined' && returnUrl && returnUrl.indexOf('/') === 0 && returnUrl !== '/') {
        window.location.href = returnUrl;
      }
    } else {
      throw new Error('Something bad, failed to authorize');
    }
  };

  const login = async (email: string, password: string, returnUrl?: string): Promise<void> => {
    const authHandler: AuthHandler = authActionsHandler();
    await authHandler.login({ email, password });

    renewStateFromCookies(returnUrl);
  };

  const logout = (): boolean => {
    document.cookie = 'jwt_p=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = 'jwt_s=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    setUser(false);

    return true;
  };

  return {
    user,
    login,
    renewStateFromCookies,
    logout,
  };
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthContext => {
  return useContext(authContext);
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
