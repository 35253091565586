import '../styles/index.css';
import App from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import NextNprogress from 'nextjs-progressbar';
import { SWRConfig } from 'swr';
import { ProvideAuth } from '../hooks/use-auth';
import ErrorBoundary from '../components/ErrorBoundary';

export default class MyApp extends App {
  public render(): React.ReactElement {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta charSet="UTF-8" />
          <title>SerpWall</title>
          <meta name="robots" content="noindex" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="theme-color" content="#cccccc" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2b5797" />
          <meta name="apple-mobile-web-app-title" content="SerpWall" />
          <meta name="application-name" content="SerpWall" />
          <meta name="msapplication-TileColor" content="#2b5797" />
          <meta name="theme-color" content="#2b5797" />
        </Head>
        <SWRConfig
          value={{
            onError: (error) => {
              if ((error?.message || '').toLowerCase().indexOf('not authorized') > -1) {
                window.location.href = `/login?not_authorized=true&returnUrl=${encodeURIComponent(
                  window.location.pathname
                )}`;
              } else {
                // eslint-disable-next-line no-console
                console.error(error);
              }
            },
          }}
        >
          <ProvideAuth>
            <NextNprogress color="rgb(12,74,110)" startPosition={0.3} stopDelayMs={200} height={3} />
            <ErrorBoundary>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...pageProps} />
            </ErrorBoundary>
            <div id="modal-root" />
          </ProvideAuth>
        </SWRConfig>
      </>
    );
  }
}
