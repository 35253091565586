import { RequestToLoginUser, AuthHandler } from '../../interfaces';
import Users from '../data-fetchers/users';

const authActionsHandler = (): AuthHandler => ({
  login: async (userObject: RequestToLoginUser): Promise<unknown> => {
    return Users.fetch(`/api/${Users.namespace}/login`, {
      body: JSON.stringify(userObject),
      method: 'POST',
    }).catch((err: Error) => {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    });
  },
});

export default authActionsHandler;
