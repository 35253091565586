/* eslint-disable @typescript-eslint/ban-types */
import 'cross-fetch/polyfill';

class Base {
  static namespace = '';

  static async fetch<U extends object>(url: string, options = {}): Promise<U> {
    if (typeof Window === 'undefined') {
      return Promise.resolve({} as U);
    }

    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        cache: 'no-cache',
        mode: 'same-origin',
        credentials: 'same-origin',
      },
      ...options,
    })
      .then((response) => {
        if (!response.ok) {
          // App thrown error
          if (response.status === 400) {
            return response.json().then((json) => {
              throw new Error(json?.message?.replace(/^Error: /, '') || 'Backend error');
            });
          }

          throw new Error(response.statusText);
        }

        return response.json() as U;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  static async getAll<T extends object>(): Promise<T[]> {
    return this.fetch(`/api/${this.namespace}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async get<T extends object>(id: string): Promise<T> {
    return this.fetch(`/api/${this.namespace}/${id}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async create<T extends object, U extends object>(insertObject: T): Promise<U> {
    return this.fetch(`/api/${this.namespace}`, {
      body: JSON.stringify(insertObject),
      method: 'POST',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // static async update<T extends object>(body: T): Promise<T> {
  //   return this.fetch(`/api/${this.namespace}`, {
  //     body,
  //     method: 'PUT',
  //   });
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async upsert<T extends object>(uuid: string, upsertObject: Record<string, unknown>): Promise<T> {
    return this.fetch(`/api/${this.namespace}/${uuid}`, {
      body: JSON.stringify(upsertObject),
      method: 'POST',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async delete(uuid: string): Promise<boolean> {
    return this.fetch(`/api/${this.namespace}/${uuid}`, {
      method: 'DELETE',
    }).then(() => true);
  }
}

export default Base;
